<template>
  <div id="setting">
    <div class="cm-content">
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="店铺状态">
          <el-radio-group v-model="form.store_state">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="-1">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="授权地址">
          <el-input size="small" type="text" v-model="form.auth_url"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="handleOk('form')"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import setting from "@/api/setting";

export default {
  name: "setting",
  data() {
    return {
      form: {
        store_state: 1,
        auth_url: "",
      },
    };
  },
  created() {
    this.handleGet();
  },
  methods: {
    handleGet() {
      setting.get().then((res) => {
        this.form = res.results;
      });
    },
    handleOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.form;
          setting
            .update(data)
            .then(() => {
              this.visible = false;
              this.$message({
                type: "success",
                message: "修改成功！",
              });
              this.$emit("change", false);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="stylus">
#setting {
  display: flex;
  height: 100%;
}
</style>
