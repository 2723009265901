import api from "@/api";

const setting = {
  get() {
    return api.get(`/setting`);
  },
  update(data) {
    return api.patch(`/setting`, data);
  },
};

export default setting;
